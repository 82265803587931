import React from "react";
import Home from "components/home/home";

import "./App.css";

const App = () => {
  return (
    <div className="App" style={{ position: "relative" }}>
      <Home/>
    </div>
  );
}

export default App;
